//
//    Generated by trinidad-ui!
//
//
//    ██████╗  ██████╗     ███╗   ██╗ ██████╗ ████████╗    ███████╗██████╗ ██╗████████╗
//    ██╔══██╗██╔═══██╗    ████╗  ██║██╔═══██╗╚══██╔══╝    ██╔════╝██╔══██╗██║╚══██╔══╝
//    ██║  ██║██║   ██║    ██╔██╗ ██║██║   ██║   ██║       █████╗  ██║  ██║██║   ██║
//    ██║  ██║██║   ██║    ██║╚██╗██║██║   ██║   ██║       ██╔══╝  ██║  ██║██║   ██║
//    ██████╔╝╚██████╔╝    ██║ ╚████║╚██████╔╝   ██║       ███████╗██████╔╝██║   ██║
//    ╚═════╝  ╚═════╝     ╚═╝  ╚═══╝ ╚═════╝    ╚═╝       ╚══════╝╚═════╝ ╚═╝   ╚═╝
//
//
//    All changes in this file will be overwritten next time
//    someone adds or generates new assets.
//
//    Read more on https://git.svenskaspel.se/trinidad/trinidad-ui ...
//
//    @author Team Design-tech
//
//    ---

import PropTypes from 'prop-types';
import './../stylesheets/icon.less';
import './../stylesheets/icon-custom.less';

const Icon = ({
  color,
  className,
  size,
  icon,
  ...props
}) => {
  const componentClasses = ['icon'];

  componentClasses.push(color ? color : 'icon-default');
  /* eslint-disable react/prop-types, react/destructuring-assignment */
  // Deprecated - use className
  if (props.class) {
    componentClasses.push(props.class);
  }
  if (props.title) {
    componentClasses.push(props.title);
  }
  /* eslint-enable react/prop-types, react/destructuring-assignment */
  if (className) {
    componentClasses.push(className);
  }
  if (size) {
    componentClasses.push(`icon-${size}`);
  }
  if (icon) {
    componentClasses.push(`icon-${icon}`);
  }

  return (
    <i className={componentClasses.join(' ')} />
  );
};

Icon.defaultProps = {
  size: '300'
};

Icon.propTypes = {
  /**
   * Add custom classes
   */
  className: PropTypes.string,

  /**
   * Set the icon color
   */
  color: PropTypes.oneOfType([
    PropTypes.oneOf([
      'icon-default',
      'icon-inverted'
    ]),
    PropTypes.string
  ]),

  /**
   * Which icon to show
   */
  icon: PropTypes.oneOfType([
    PropTypes.oneOf([
      'accessibility',
      'allsvenskan',
      'alphabetic',
      'arrow-down',
      'arrow-left',
      'arrow-right',
      'arrow-up',
      'balance',
      'bank-account',
      'bingo',
      'business-travel',
      'calendar',
      'caret-down',
      'caret-left',
      'caret-right',
      'caret-up',
      'chat-filled',
      'chat-host',
      'chat-robot',
      'chat',
      'checkmark-thin',
      'checkmark',
      'clock-simple',
      'clock-solid',
      'clock',
      'close',
      'collapse',
      'communication',
      'compare',
      'contact-us',
      'copy-properties',
      'copy',
      'coverage-active',
      'coverage',
      'creditcard',
      'crown',
      'curiosity',
      'customer-service',
      'customized-content',
      'deposit-coin',
      'deposit-limits',
      'deposit',
      'document',
      'drag-and-drop',
      'dustbin',
      'e-post',
      'enkelrader-tur',
      'enkelrader',
      'expand',
      'extend-game',
      'external-system-games',
      'eye-closed',
      'eye',
      'favourite-leagues',
      'filter',
      'finish-flag',
      'focus',
      'form-down',
      'form-negative',
      'form-neutral',
      'form-positive',
      'form-up',
      'free-reduce',
      'game-basketball',
      'game-floorball',
      'game-handball',
      'game-history',
      'game-hockey',
      'game-package',
      'game-soccer',
      'game-sport',
      'game-tennis',
      'game-track',
      'game-winter',
      'games',
      'head',
      'health',
      'hedge-full',
      'hedge-half',
      'help-2',
      'help-outline',
      'help',
      'history-win',
      'history',
      'horse',
      'hot',
      'icecream',
      'infinity-icon',
      'info',
      'information',
      'ladder-down',
      'ladder-up',
      'latest-games',
      'link-external',
      'link',
      'login',
      'loose',
      'mail-outline',
      'mail',
      'marker',
      'minus',
      'money-laundry',
      'my-limits',
      'my-settings',
      'notifications',
      'odds',
      'olympics',
      'optional-copy',
      'pause',
      'person',
      'pie-chart',
      'pin',
      'pix',
      'play-circle',
      'plus',
      'qr',
      'r-system',
      'rectify-correct',
      'rectify-error',
      'refresh-pix',
      'refresh',
      'remove-sign',
      'rensa-app',
      'reorder',
      'rest',
      'rows-one',
      'rows-three',
      'rows-two',
      'rows',
      'scroll-down',
      'search',
      'self-test',
      'send',
      'settings',
      'simplicity',
      'slider',
      'sound-off',
      'sound-on',
      'spela-lagom',
      'spela-tillsammans',
      'spelguide',
      'spelpaus',
      'star-empty',
      'star',
      'statistics',
      'sub-menu',
      'subscription',
      'superettan',
      'systemspel',
      'team',
      'ten-papers',
      'thumbs-up',
      'tips',
      'tipsinfo',
      'trade-in-progress',
      'transaction-history',
      'triss-biltriss-tesla-y',
      'triss-biltriss-tesla',
      'triss-klover-2020',
      'triss-klover',
      'triss-stjarna',
      'triss-tv-2020',
      'triss-tv',
      'triss-win-for-life',
      'tv',
      'update',
      'upload',
      'user',
      'viewsummary',
      'win',
      'withdrawal',
      'xperten',
      'exclamation-sign',
      'adventure',
      'all-card-games',
      'all-games',
      'animals',
      'asian',
      'baccarat',
      'blackjack',
      'calendar-day',
      'calendar-month',
      'calendar-week',
      'casino',
      'christmas',
      'classic-slots',
      'classics',
      'cluster-games',
      'dice',
      'dragons',
      'easter',
      'favorites-filled',
      'favorites',
      'golden',
      'halloween',
      'jackpot',
      'jungle',
      'last-played',
      'live-casino',
      'loss-limit',
      'manga',
      'megaways',
      'mitt-casino',
      'money',
      'moneywheel',
      'monster',
      'musik',
      'new-games',
      'news',
      'paylines',
      'pirates',
      'players',
      'poker',
      'prehistoric',
      'roulette',
      'rtp',
      'sci-fi',
      'space',
      'studio',
      'summer',
      'superhero',
      'supplier',
      'table-games',
      'time-limit',
      'video-poker',
      'video-slots',
      'virtualsports',
      'gdpr-cookies',
      'gdpr-custom-content',
      'gdpr-information',
      'gdpr-offers',
      'gdpr-terms-changes',
      'gdpr-welcome',
      'mobile-verified',
      'mobile',
      'vinnare-inverted',
      'vinnare',
      'andelsspel',
      'badge-arrow-down',
      'badge-arrow-left',
      'badge-arrow-right',
      'badge-arrow-up',
      'beta',
      'bli-kund',
      'brands',
      'competitions-offers',
      'components',
      'content',
      'foundation',
      'grassroots',
      'icon-horse',
      'maximize',
      'menu-down',
      'menu-left',
      'menu-right',
      'menu-up',
      'menu',
      'min-spelkoll',
      'minimize',
      'nav-arena',
      'nav-bets',
      'nav-games',
      'nav-home',
      'nav-more-vertical',
      'nav-more',
      'nav-my-bets',
      'nav-my-games',
      'nav-new-customer',
      'nav-ready-games',
      'nav-results',
      'nav-singlerows',
      'nav-spela-tillsammans',
      'nav-sportservice',
      'patterns',
      'shortcut',
      'terms-conditions',
      'var-spelkoll',
      'card',
      'connection-live',
      'connection-lost',
      'diamond',
      'my-choice',
      'my-habits',
      'overview',
      'penalty',
      'points',
      'substitution-in',
      'substitution-out',
      'target',
      'team-captain',
      'trophy-tips-sm',
      'trophy',
      'umbrella',
      'all-meetings',
      'blinkers-australian',
      'blinkers',
      'fast-forward',
      'horseshoe-off',
      'horseshoe-on',
      'live-betting',
      'livestream',
      'lock-closed',
      'lock-open',
      'most-played',
      'picture-in-picture',
      'play',
      'rectangle-pause',
      'rewind',
      'u-system',
      'volume-low',
      'volume-medium',
      'volyme-off',
      'volyme-on',
      'weather-cloudy',
      'weather-partly-cloudy',
      'weather-rainy',
      'weather-sunny',
      'selfservice-arrow-left',
      'selfservice-cart',
      'selfservice-fail',
      'selfservice-live',
      'selfservice-loading',
      'selfservice-pay',
      'selfservice-success',
      'arena',
      'facebook',
      'few-members',
      'games-corporation',
      'games-share',
      'instagram',
      'linkedin',
      'multiplayer',
      'profits',
      'singleplayer',
      'x',
      'youtube'
    ]),
    PropTypes.string
  ]).isRequired,

  /**
   * Set size of the icon
   */
  size: PropTypes.oneOfType([
    PropTypes.oneOf([
      100,
      200,
      300,
      400,
      500,
      600,
      700,
      800,
      900,
      950,
      1000,
      1100
    ]),
    PropTypes.string,
    PropTypes.number
  ])
};

export default Icon;
